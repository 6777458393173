var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: "意见反馈", fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.goBack()
                },
              },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
          _c("div", { attrs: { slot: "right" }, slot: "right" }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "feedback" },
        [
          _c("van-field", {
            attrs: {
              rows: "3",
              autosize: "",
              type: "textarea",
              placeholder: "请输入留言",
            },
            model: {
              value: _vm.content,
              callback: function ($$v) {
                _vm.content = $$v
              },
              expression: "content",
            },
          }),
          _c("div", { staticClass: "box" }, [
            _c("i", {
              staticClass: "iconfont icon-link",
              staticStyle: { "font-size": "20px" },
              on: { click: _vm.uploadPhoto },
            }),
          ]),
          _c(
            "div",
            { staticClass: "file-list" },
            _vm._l(_vm.fileList, function (item, i) {
              return _c("div", { key: i, staticClass: "file-box" }, [
                _c("img", { attrs: { src: item.content, alt: "" } }),
                _c("i", {
                  staticClass: "iconfont icon-clear-2",
                  on: {
                    click: function ($event) {
                      return _vm.deleteImg(i)
                    },
                  },
                }),
              ])
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "btn" },
            [
              _c(
                "van-button",
                { attrs: { type: "info" }, on: { click: _vm.submit } },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }