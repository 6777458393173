<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header" title="意见反馈" fixed>
      <fb-button slot="left" icon="back" @click="goBack()" size="small" type="primary">
        返回
      </fb-button>
      <div slot="right"></div>
    </fb-header>
    <div class="feedback">
      <van-field
        v-model="content"
        rows="3"
        autosize
        type="textarea"
        placeholder="请输入留言"
      />
      <div class="box">
        <i @click="uploadPhoto" class="iconfont icon-link" style="font-size: 20px"></i>
      </div>
      <div class="file-list">
        <div class="file-box" v-for="(item, i) in fileList" :key="i">
          <img :src="item.content" alt="" />
          <i class="iconfont icon-clear-2" @click="deleteImg(i)"></i>
        </div>
      </div>
      <div class="btn">
        <van-button type="info" @click="submit">提交</van-button>
      </div>
    </div>
  </fb-page>
</template>
<script>
import goBackMixin from '@mixins/goBack'
import { qiniuBase } from '~api-config'
import { mapState } from 'vuex'

export default {
  name: 'feedback',
  mixins: [goBackMixin],
  components: {},
  data() {
    return {
      content: '', // 输入框的内容
      fileList: [], // 输入区域的文件列表
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
  },
  methods: {
    uploadPhoto() {
      console.log('uploadPhoto...s1')
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.toAndrPickFile(1)
        return false
      } else if (ua === 'fb-flutter-Webview') {
        console.log('uploadPhoto...flutter')
        window.toAndrPickFile.postMessage('1')
        return false
      } else {
        if (document.getElementById('file')) {
          document.getElementById('file').remove()
        }
        const ipt = document.createElement('input')
        ipt.id = 'file' // 设置ID以便删除
        ipt.type = 'file'
        ipt.multiple = true // 开启支持多选
        ipt.style.display = 'none'
        document.body.appendChild(ipt)
        document.getElementById('file').onchange = () => {
          this.recive = true
          Array.from(ipt.files).forEach((item) => {
            const qiniu = new this.$qiniu()
            qiniu.UploadFile(
              'DEFAULT',
              item,
              {
                next: (file) => {},
                complete: () => {},
                error: () => {
                  this.$toast('上传失败，上传终止')
                },
              },
              (fileopt, key, file) => {
                // 拼接完整的图片的地址
                const url = window.location.protocol + qiniuBase + '/' + key
                this.fileList.push({
                  name: fileopt.name,
                  content: url,
                  size: fileopt.size,
                  type: fileopt.type,
                })
              }
            )
          })
        }
        ipt.click()
      }
    },
    // 接收原生端上传回调
    uploadFilesResultCallBack(data) {
      const list = JSON.parse(data)
      list.map((item) => {
        this.fileList.push({
          name: item.name,
          content: item.url,
          size: item.size,
          type: 'img',
        })
      })
    },
    // 点击删除图标
    deleteImg(index) {
      this.fileList.splice(index, 1)
    },
    // 点击提交按钮
    submit() {
      if (this.fileList.length === 0 && this.content.trim() === '') {
        return this.$message.error('请不要提交空白内容')
      }
      this.submitBtnLoad = true
      const list = [
        {
          name: '',
          content: this.content.trim(),
          size: '',
          type: 'string',
        },
        ...this.fileList,
      ]
      const params = {
        content: JSON.stringify(list),
        clientType: window.navigator.userAgent || 'mobile',
        menu: this.menu,
      }
      if (this.userInfo) {
        params.userId = this.userInfo.id
        params.nickname = this.userInfo.nickName
        params.account = this.userInfo.account
        params.province = this.userInfo.province
        params.city = this.userInfo.city
      }
      this.$axios
        .post(`${this.$base}/admin/feedback`, params)
        .then((res) => {
          this.submitBtnLoad = false
          if (res.data.code === 200) {
            this.content = ''
            this.fileList = []
            this.$notify({
              type: 'success',
              message: '提交成功',
            })
            setTimeout(() => {
              this.goBack()
            }, 1000)
          } else {
            this.$notify(res.data.message)
          }
        })
        .catch(() => {
          this.$notify('提交失败，请稍后重试')
          this.submitBtnLoad = false
        })
    },
  },
  created() {
    // 关联组件内方法ocrCaseResultCallback 至 JSBridge/uploadFiles
    this.$hook(this.$bridge.uploadFiles, this.uploadFilesResultCallBack)
    // 组件销毁的时候释放桥接方法
    this.$once('hook:destroyed', () => {
      this.$unhook(this.$bridge.uploadFiles)
    })
  },
  mounted() {},
}
</script>
<style lang="stylus">
.feedback
  & .van-field__control
    border 1px solid #CCC
  & .box
    padding 0 20px
    box-sizing border-box
  & .file-list
    display flex
    align-items center
    flex-wrap wrap
    padding 0 20px
    box-sizing border-box
    & .file-box
      margin-right 10px
      margin-top 10px
      width 80px
      height 80px
      position relative
      & img
        width 100%
        height 100%
      & .icon-clear-2
        font-size 18px
        color red
        position absolute
        top -10px
        right -10px
  & .btn
    margin-top 20px
    display flex
    flex-direction row
    align-items center
    justify-content center
</style>
